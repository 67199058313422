<template>
  <div>
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55 </v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">{{
            snackbar.name
          }}</span>
          <br />
          {{ error_msg }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12" class="text-center">
          <h3 class="text-h3 text-typo font-weight-bold mt-9">
            {{ $t("Build Your Profile") }}
          </h3>
          <h5 class="text-h5 text-secondary font-weight-normal">
            {{ $t("This information will let us know more about you.") }}
          </h5>
        </v-col>
        <v-stepper
          alt-labels
          elevation="0"
          v-model="e1"
          class="bg-transparent overflow-visible w-100 mx-auto"
        >
          <v-row>
            <v-col lg="8" cols="12" class="mx-auto mt-9">
              <v-stepper-header class="shadow-0">
                <v-stepper-step step="1" color="#344767">
                  <span
                    class="
                      text-secondary
                      font-weight-normal
                      d-block
                      text-center
                    "
                    style="width: max-content"
                    >{{ $t("user.Basic information") }}</span
                  >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" color="#344767">
                  <span
                    class="
                      text-secondary
                      font-weight-normal
                      d-block
                      text-center
                    "
                    style="width: max-content"
                    >{{ $t("user.authentication and authorization") }}</span
                  >
                </v-stepper-step>
              </v-stepper-header>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="8" cols="12" class="mx-auto">
              <div v-if="errors" class="alert alert-danger">
                <ul class="alert alert-danger">
                  <li v-for="(value, key, index) in errors" :key="index">
                    {{ value.toString() }}
                  </li>
                </ul>
              </div>

              <v-form ref="newUserForm">
                <v-stepper-items
                  class="border-radius-xl overflow-hidden shadow-lg mt-5 mb-9"
                >
                  <v-stepper-content
                    step="1"
                    class="bg-white border-radius-xl px-4 pt-4"
                  >
                    <v-card>
                      <div>
                        <v-row class="text-center">
                          <v-col cols="10" class="mx-auto">
                            <h5 class="text-h5 text-typo font-weight-bold mb-2">
                              {{ $t("user.Basic information") }}
                            </h5>
                            <p class="text-body">
                              {{
                                $t(
                                  "user.Enter user basic info, and upload user avater"
                                )
                              }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-2">
                          <v-col sm="4" cols="12" class="text-center">
                            <v-badge
                              bottom
                              color="bg-gradient-light accent-4 text-dark text-xxs border-radius-md shadow"
                              offset-x="10"
                              offset-y="10"
                              :icon="avatar_src ? '' : 'fa-plus text-xxs'"
                            >
                              <v-avatar rounded width="110" height="110">
                                <img
                                  v-if="avatar_src"
                                  :src="avatar_src"
                                  :lazy-src="avatar_src"
                                  alt="Avatar"
                                  class="border-radius-lg"
                                />
                                <img
                                  v-else
                                  src="@/assets/img/no-avatar.jpg"
                                  alt="Avatar"
                                  class="border-radius-lg"
                                />
                                <span
                                  @click="removeImage()"
                                  v-if="avatar_src"
                                  class="
                                    v-badge__badge
                                    bg-gradient-light
                                    accent-4
                                    text-dark text-xxs
                                    border-radius-md
                                    shadow
                                  "
                                  style="
                                    inset: calc(100% - 10px) auto auto
                                      calc(100% - 10px);
                                    z-index: 9;
                                    cursor: pointer;
                                  "
                                  ><i
                                    aria-hidden="true"
                                    class="
                                      v-icon
                                      notranslate
                                      fa fa-times
                                      text-xxs
                                      theme--light
                                    "
                                  ></i
                                ></span>

                                <v-file-input
                                  class="custom-avatar"
                                  id="files"
                                  ref="files"
                                  accept="image/png, image/jpeg, image/bmp"
                                  @change="handleFilesUpload"
                                  v-model="files"
                                />
                              </v-avatar>
                            </v-badge>
                          </v-col>
                          <v-col sm="8" cols="12">
                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("First Name") }}</label
                            >
                            <v-text-field
                              outlined
                              v-model="form.first_name"
                              :rules="rules.first_name"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              placeholder="eg. Michael"
                              class="
                                font-size-input
                                placeholder-lighter
                                text-light-input
                              "
                            >
                            </v-text-field>

                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("Last Name") }}</label
                            >
                            <v-text-field
                              outlined
                              v-model="form.last_name"
                              :rules="rules.last_name"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              placeholder="Kim"
                              class="
                                font-size-input
                                placeholder-lighter
                                text-light-input
                              "
                            >
                            </v-text-field>

                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("auth.Email Address") }}</label
                            >
                            <v-text-field
                              outlined
                              type="email"
                              v-model="form.email"
                              :rules="rules.email"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              placeholder="Eg.soft@alborj.com"
                              class="
                                font-size-input
                                placeholder-lighter
                                text-light-input
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="text-end">
                        <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs
                            btn-default
                            bg-gradient-default
                            py-5
                            px-6
                            mt-2
                            mb-2
                            me-2
                          "
                          color="primary"
                          @click="e1 = 2"
                        >
                          {{ $t("general.Next") }}
                        </v-btn>
                      </div>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-content
                    step="2"
                    class="bg-white border-radius-xl px-4 pt-4"
                  >
                    <v-card>
                      <div>
                        <v-row class="text-center">
                          <v-col cols="10" class="mx-auto">
                            <h5 class="text-h5 text-typo font-weight-bold mb-2">
                              {{ $t("user.authentication and authorization") }}
                            </h5>
                            <p class="text-body">
                              {{
                                $t(
                                  "user.Enter Strong password, and select user role"
                                )
                              }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-2">
                          <v-col sm="12" cols="12">
                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("auth.Password") }}</label
                            >
                            <v-text-field
                              outlined
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show1 ? 'text' : 'password'"
                              @click:append="show1 = !show1"
                              v-model="form.password"
                              :rules="rules.password"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              :placeholder="$t('auth.Password')"
                              class="
                                font-size-input
                                placeholder-lighter
                                text-light-input
                              "
                            >
                            </v-text-field>

                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("auth.Repeat Password") }}</label
                            >
                            <v-text-field
                              outlined
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show2 ? 'text' : 'password'"
                              @click:append="show2 = !show2"
                              v-model="form.password_confirmation"
                              :rules="rules.password_confirmation"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              :placeholder="$t('auth.Repeat Password')"
                              class="
                                font-size-input
                                placeholder-lighter
                                text-light-input
                              "
                            >
                            </v-text-field>

                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("user.Role") }}</label
                            >
                            <v-select
                              outlined
                              :items="roles"
                              v-model="form.roles"
                              lazy-validation
                              :value="form.roles"
                              :item-text="'name'"
                              :item-value="'id'"
                              color="rgba(0,0,0,.6)"
                              height="38"
                              multiple
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="d-flex mt-10">
                        <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs text-dark
                            btn-light
                            bg-gradient-light
                            py-5
                            px-6
                            mt-6
                            mb-2
                            ms-2
                          "
                          @click="e1 = 1"
                        >
                          {{ $t("general.Prev") }}
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs
                            btn-default
                            bg-gradient-default
                            py-5
                            px-6
                            mt-6
                            mb-2
                            me-2
                            ms-auto
                          "
                          color="primary"
                          @click="handleSubmit()"
                        >
                          {{ $t("general.Submit") }}
                          <i v-if="loading" class="fa fa-circle-o-notch"></i>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-form>
            </v-col>
          </v-row>
        </v-stepper>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Wizard",
  data() {
    return {
      show1: false,
      show2: false,
      valid: false,
      roles: [],
      files: [],
      snackbar: {
        color: "#f5365c",
        class: "danger",
        name: "Danger",
        visible: false,
      },
      avatar_src: "",
      form: {
        first_name: "",
        last_name: "",
        email: "",
        paswword: "",
        paswword_confirmation: "",
        roles: null,
      },
      rules: {
        first_name: [
          (v) => !!v || this.$i18n.t("first name is required"),
          (v) =>
            v.length <= 20 ||
            this.$i18n.t("Name must be less than 20 characters"),
        ],
        last_name: [
          (v) => !!v || this.$i18n.t("last name is required"),
          (v) =>
            v.length <= 20 ||
            this.$i18n.t("auth.Name must be less than 20 characters"),
        ],
        email: [
          (v) => !!v || this.$i18n.t("auth.E-mail is required"),
          (v) =>
            /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              v
            ) || this.$i18n.t("auth.E-mail must be valid"),
        ],
        password: [
          (v) => !!v || this.$i18n.t("auth.Password is required"),
          (v) =>
            (v && v.length >= 6) ||
            this.$i18n.t("auth.minimum 6 characters at least"),
        ],
        password_confirmation: [
          (v) => !!v || this.$i18n.t("auth.Password Confirmation is required"),
          (v) =>
            v == this.form.password ||
            this.$i18n.t(
              "auth.Password and password confirmation does not match"
            ),
        ],
      },
      errors: {},
      error_msg: "",
      loading: false,
      e1: 1,
      toggle_exclusive: 2,
    };
  },
  methods: {
    validate() {
      return this.$refs.newUserForm.validate();
    },
    getRoles() {
      this.$http.get("/users/get-roles").then(
        (response) => {
          this.roles = response.data.data;
        },
        (error) => {
          // console.log(error);
        }
      );
    },
    nextStep(val) {
      if (this.validate()) {
        this.e1 = val;
      }
    },
    async handleSubmit() {
      this.error_msg = "";
      this.loading = true;
      // console.log(this.files);
      const formData = new FormData();
      formData.append("avatar", this.files);
      formData.append("first_name", this.form.first_name);
      formData.append("last_name", this.form.last_name);
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      formData.append("password_confirmation", this.form.password_confirmation);
      formData.append("roles", this.form.roles);
      formData.append("admin", 1);
      if (this.validate()) {
        await axios
          .post("users/create", formData)
          .then((response) => {
            // console.log(response);
            this.error_msg = response.data.message;
            this.snackbar.color = "#2dce89";
            this.snackbar.class = "success";
            this.snackbar.name = "Success";
            this.snackbar.visible = true;
            // this.$router.push("/" + response.data.user.id);
            this.$router.push({ path: "/users/admins", params: this.snackbar });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            if (error && error.response.status == 422) {
              this.errors = error.response.data.errors;
              this.error_msg = "Please Check errors before submit";
            }
            this.error_msg =
              (error.response && error.response.data.message) ||
              error.message ||
              error.toString();
            this.snackbar.color = "#f5365c";
            this.snackbar.class = "error";
            this.snackbar.name = "Error";
            this.snackbar.visible = true;
          });
      } else {
        this.error_msg = "Please Check errors before submit";
        this.snackbar.color = "#f5365c";
        this.snackbar.class = "error";
        this.snackbar.name = "Error";
        this.snackbar.visible = true;
      }

      //   this.$store.dispatch("auth/register", formData).then(
      //     (response) => {
      //       this.$router.push("/" + response.user.id);
      //     },
      //     (error) => {
      //       // console.log(error);
      //       this.loading = false;
      //       if (error.response.status == 422){
      //               let errors = Object.values(error.response.data.errors);
      //               this.errors  = errors.flat();
      //       }
      //
      //       this.error_msg =
      //         (error.response && error.response.data.message) ||
      //         error.message ||
      //         error.toString();
      //       // console.log(this.error_msg);
      //     }
      //   );
      // } else {
      //   this.loading = false;
      //   this.error_msg = "Please Check errors and try again.";
      //   return;
      // }
    },
    handleFilesUpload() {
      this.files = event.target.files[0];
      this.avatar_src = URL.createObjectURL(event.target.files[0]);
    },

    removeImage() {
      this.files = [];
      this.avatar_src = "";
    },
  },
  mounted() {
    this.getRoles();
    document.title = this.$i18n.t("user.Create");
  },
};
</script>

<style>
.custom-avatar * {
  width: 100%;
  height: 100%;
}

.custom-avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  opacity: 0;
}
.custom-avatar .v-input__control {
  display: none !important;
}
.v-avatar {
  overflow: visible;
}
</style>